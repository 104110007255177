import React from "react";
import ClimateCheckLogo from "../../../ui/ClimateCheckLogo/ClimateCheckLogo";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

const Header = ({ onClose }: { onClose: () => void }) => {
  return (
    <header className="white-header">
      <ClimateCheckLogo />
      <Icon
        className="close-icon"
        icon={IconNames.CROSS}
        iconSize={40}
        color="#BFBFBF"
        onClick={onClose}
      />
    </header>
  );
};

export default Header;

import React from "react"
import { Text } from "@blueprintjs/core"
import { Formik, Form, Field } from "formik"
import Pin from "@images/img-min/pin.svg"
import RadioField from "@components/fields/RadioField"
import PremiumCheckoutSchema from "../../../schemas/premiumReportSchema"

const GetPremiumForm = ({ displayAddress, onSubmit }) => {
  return (
    <div className="gpf-content">
      <h1>
        Get your <span>free</span> full report
      </h1>
      <div className="gpf-content-divider"></div>
      <h3>property address</h3>
      <div className="gpf-content-address">
        <Pin />
        <Text tagName="span">{displayAddress}</Text>
      </div>
      <Formik
        initialValues={{ email: "", description: [""] }}
        onSubmit={onSubmit}
        validationSchema={PremiumCheckoutSchema}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
        }) => {
          return (
            <Form className="gpf-content-form" onSubmit={handleSubmit}>
              <div className="gpf-content-form-divider"></div>
              <h3 className="no-caps">
                What email address should we send the report to?
              </h3>
              <Field
                id="email"
                type="text"
                placeholder="Enter email address..."
                className="email-field"
                onChange={handleChange}
                value={values.email}
              />
              <div className="gpf-content-form-divider"></div>
              <h3 className="no-caps">
                Which of the following describes you? (Optional)
              </h3>
              <p className="help-text">
                Your feedback will help us improve our service.
              </p>
              <div className="gpf-content-form-radios">
                <RadioField
                  id="homeBuyer"
                  name="description"
                  value="Home buyer"
                  onChange={handleChange}
                />
                <RadioField
                  id="homeSeller"
                  name="description"
                  value="Home seller"
                  onChange={handleChange}
                />
                <RadioField
                  id="homeOwner"
                  name="description"
                  value="Home owner"
                  onChange={handleChange}
                />
                <RadioField
                  id="investor"
                  name="description"
                  value="Investor"
                  onChange={handleChange}
                />
                <RadioField
                  id="realtorBroker"
                  name="description"
                  value="Realtor/Broker"
                  onChange={handleChange}
                />
              </div>
              <div className="gpf-content-form-divider"></div>
              <button
                className="gpf-content-form-button"
                type="submit"
                disabled={isSubmitting || !isValid || !dirty}
              >
                Finalize order
              </button>
              <Text className="gpf-content-form-help-text">
                You will receive your full report via email within the next few
                minutes
              </Text>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default GetPremiumForm

import React from "react";
import { Field } from "formik";

type Props = {
  id: string;
  name: string;
  value: string;
  onChange: Function;
};

const RadioField = ({ id, name, value, onChange }: Props) => {
  return (
    <>
      <Field
        type="checkbox"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
      />
      <label className="custom-checkbox-label" htmlFor={id}>
        {value}
      </label>
    </>
  );
};

export default RadioField;

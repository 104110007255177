import React from "react"
import { navigate } from "gatsby"
import Pin from "@images/img-min/pin.svg"

type Props = {
  email: string
  address: string
}

const OrderConfirmed = ({ email, address }: Props) => {
  return (
    <div className="oc-page">
      <h1>Your order is confirmed!</h1>
      <h4>Your free report is being generated now.</h4>
      <p>
        We're emailing the PDF to{" "}
        <strong>{email}</strong>.
      </p>
      <div className="oc-page-divider"></div>
      <h3>property address</h3>
      <div className="oc-page-address">
        <Pin />
        <span>{address}</span>
      </div>
      <div className="oc-page-divider"></div>
      <button
        className="oc-page-button"
        onClick={() => navigate("/")}
      >
        Search for another property
      </button>
      <div className="oc-page-footer">
        <p className="oc-page-footer-first">Have questions or feedback?</p>
        <p className="oc-page-footer-second">
          Get in touch with us at{" "}
          <a href="mailto:info@climatecheck.com" rel="noopener">
            info@climatecheck.com
          </a>
          .
        </p>
      </div>
    </div>
  )
}

export default OrderConfirmed

import React from "react"
import { graphql, navigate } from "gatsby"
import Header from "@components/global/Header"
import OrderConfirmed from "@components/global/OrderConfirmed"
import ClientServices from "@services/ClientServices"
import useUrlQuery from "@hooks/useUrlQuery"
import OrderNotification from "../client-email/OrderNotification"
import GetPremiumForm from "@components/GetPremiumPage/GetPremiumForm"
import SEO from "@components/SEO"
import "@styles/Components/getPremium.scss"
import Footer from "@components/global/Footer"

type FormValues = {
  email: string
  description: string[]
}

const GetPremium = ({ location, data: { seo } }) => {
  const { address, checkout, email } = useUrlQuery(location, [
    "address",
    "checkout",
    "email",
  ])
  const clientServices = new ClientServices()
  const displayAddress = clientServices.strings.getDisplayAddress(address)
  const customSeo = {
    title: seo?.seo?.title || "Get Premium",
    ...seo?.seo,
  }

  const handleSubmit = async (values: FormValues, { setSubmitting }) => {
    setSubmitting(true)
    const { email, description } = values
    const bodyData = {
      email,
      description,
      propertyAddress: address,
    }

    await clientServices.integrationClient.sendEmail(
      OrderNotification.to(values.email),
      OrderNotification.data(bodyData),
      OrderNotification.subject(),
      OrderNotification.body(bodyData)
    )
    setSubmitting(false)
    navigate(
      `/get-premium?address=${encodeURIComponent(
        address
      )}&email=${encodeURIComponent(values.email)}&checkout=done`
    )
  }

  return (
    <div className="gp-page">
      <SEO seo={customSeo} />
      <Header
        onClose={() =>
          navigate(`/report?address=${encodeURIComponent(address)}`)
        }
      />
      {checkout && checkout === "done" && email ? (
        <OrderConfirmed address={address} email={email} />
      ) : (
        <GetPremiumForm
          displayAddress={displayAddress}
          onSubmit={handleSubmit}
        />
      )}
      <Footer/>
    </div>
  )
}

export default GetPremium

export const query = graphql`
  query getPremium {
    seo: sanityPagesSeo(slug: { current: { eq: "/get-premium" } }) {
      seo {
        title
        keywords
        description
      }
    }
  }
`

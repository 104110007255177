import React from "react"
import { Link, navigate } from "gatsby"
import ClimateCheckLogo from "../../../ui/ClimateCheckLogo/ClimateCheckLogo"
import TwitterIcon from "@images/icons/icon-social-twitter-black.svg"
import FacebookIcon from "@images/icons/icon-social-facebook-black.svg"
import LinkedInIcon from "@images/icons/icon-social-linkedin-black.svg"
import InstagramIcon from "@images/icons/icon-social-instagram-black.svg"
import * as styles from "./Footer.module.scss"
import StateFooter from "../CorporativeFooter/StateFooter"

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div>
        <StateFooter />
      </div>
      <div className={styles.container}>
        <ClimateCheckLogo color="black" />

        <div className={styles.menu}>
          <Link to="/solutions">Solutions</Link>
          <Link to="/about-us">About Us</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/about-us#our-team">Team</Link>
          <Link to="/risks/overview">Risks</Link>
          <Link to="/our-methodologies">Methodologies</Link>
        </div>

        <div className={styles.social}>
          <TwitterIcon
            onClick={() => navigate("https://twitter.com/MyClimateCheck")}
          />
          <FacebookIcon
            onClick={() => navigate("https://www.facebook.com/myclimatecheck/")}
          />
          <LinkedInIcon
            onClick={() =>
              navigate("https://www.linkedin.com/company/myclimatecheck")
            }
          />
          <InstagramIcon
            onClick={() =>
              navigate("https://www.instagram.com/myclimatecheck/")
            }
          />
        </div>

        <div className={styles.terms}>
          <Link to="/terms-of-service">Terms & Conditions</Link> |{" "}
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>
        <div className={styles.copyright}>
          Copyright © {new Date().getFullYear()} ClimateCheck®,{" "}
          <br className="mobile-break" />
          Inc. <br className="mobile-break" />
          <br className="mobile-break" />
          All rights reserved.
        </div>
      </div>
    </footer>
  )
}

export default Footer

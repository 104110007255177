import * as Yup from "yup";

const PremiumCheckoutSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email Address must be a valid email!")
    .min(4, "Too Short!")
    .max(100, "Too Long!")
    .required("Required"),
  description: Yup.array().of(Yup.string()),
});

export default PremiumCheckoutSchema;

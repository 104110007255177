import React, {useState} from "react"
import { Link } from "gatsby"
import { states } from "../../../utils/customDate"

const StateFooter = () => {
  const [showMore, setShowMore] = useState(false)

  return (
    <div className="corporative-footer-container">
      <div className="corporative-footer-container-title">
        <p>Search for climate change risks by state</p>
      </div>
      <div className="corporative-footer-container-list-link">
        <div className="corporative-footer-container-col">
          {states.map((state, index) => {
            {
              if (index > 23 && !showMore) {
                return (
                  <div
                    key={index}
                    className="corpotative-footer-container-list item"
                  >
                    <Link
                      className="corporative-footer-container-col-link"
                      to={`/${state
                        .split(" ")
                        .join("")
                        .toLowerCase()}`}
                    >
                      {state}
                    </Link>
                  </div>
                )
              } else {
                return (
                  <div
                    key={index}
                    className="corpotative-footer-container-list"
                  >
                    <Link
                      className="corporative-footer-container-col-link"
                      to={`/${state
                        .split(" ")
                        .join("")
                        .toLowerCase()}`}
                    >
                      {state}
                    </Link>
                  </div>
                )
              }
            }
          })}
        </div>
        <div className="showText">
            <span onClick={() => setShowMore(!showMore)}>
              <p>
              {showMore ? "Show less" : "Show more"}
              </p>
            </span>
        </div>
      </div>
    </div>
  )
}

export default StateFooter
